import axios from 'src/utils/axios';
import helpers from 'src/helpers';
import { NOTIFICATION_STATUS } from 'src/constants';
import { addNotification } from 'src/actions/notificationActions';
import {
  setBackToPartyGuestList,
} from 'src/actions/customersActions';

export const GET_CUSTOMER = '@orders/get-customer';
export const GET_CUSTOMER_SUCCESS = '@orders/get-customer-success';
export const GET_CUSTOMER_ERROR = '@orders/get-customer-error';

export const DELETE_CUSTOMER = '@orders/del-customer';
export const DELETE_CUSTOMER_SUCCESS = '@orders/del-customer-success';
export const DELETE_CUSTOMER_ERROR = '@orders/del-customer-error';
export const CLEAR_CUSTOMER_WAS_DELETED = '@orders/clear-customer-was-deleted';

export const UPDATE_CUSTOMER = '@orders/update-customer';
export const UPDATE_CUSTOMER_SUCCESS = '@orders/update-customer-success';
export const UPDATE_CUSTOMER_ERROR = '@orders/update-customer-error';

export const CREATE_CUSTOMER = '@orders/create-customer';
export const CREATE_CUSTOMER_SUCCESS = '@orders/create-customer-success';
export const CREATE_CUSTOMER_ERROR = '@orders/create-customer-error';

export const SET_UPDATE_BIRTHDAY_CAMPAIGN_IN_PB = '@orders/set-update-birthday-campaign=in-PB';

export const SET_CUSTOMER_DETAILS_TAB = '@orders/set-customer-details-tab';

export const SET_GO_TO_ORDER_DETAILS = '@orders/set-go-to-order-details';
export const CLEAR_GO_TO_ORDER_DETAILS = '@orders/clear-go-to-order-details';

export const SET_GO_TO_PARTY_DETAILS = '@orders/set-go-to-party-details';
export const CLEAR_GO_TO_PARTY_DETAILS = '@orders/clear-go-to-party-details';

export const SET_FORM_CREATE_UPDATE_CONTACT = '@orders/set-form-create-update-contact';

export const SET_VALUES_FORM_ADD_TO_PARTY = '@orders/set-values-form-add-party';

export const SET_VALUES_FORM_ADD_CUSTOMER_ORDER = '@orders/set-values-form-add-customer-order';

export const GET_PARTIES_LIST = '@account/get-parties-list';
export const GET_PARTIES_LIST_SUCCESS = '@account/get-parties-list-success';
export const GET_PARTIES_LIST_ERROR = '@account/get-parties-list-error';

export const SEND_ADD_PARTY_GUEST = '@orders/send-add-party-guest';
export const SEND_ADD_PARTY_GUEST_SUCCESS = '@orders/send-add-party-guest-success';
export const SEND_ADD_PARTY_GUEST_ERROR = '@orders/send-add-party-guest-error';

export const SEND_REMOVE_PARTY_GUEST = '@orders/send-remove-party-party';
export const SEND_REMOVE_PARTY_GUEST_SUCCESS = '@orders/send-remove-party-guest-success';
export const SEND_REMOVE_PARTY_GUEST_ERROR = '@orders/send-remove-party-guest-error';

export const GET_ORDERS_LIST = '@account/get-orders-list';
export const GET_ORDERS_LIST_SUCCESS = '@account/get-orders-list-success';
export const GET_ORDERS_LIST_ERROR = '@account/get-orders-list-error';

export const SEND_ADD_CUSTOMER_ORDER = '@orders/send-add-customer-order';
export const SEND_ADD_CUSTOMER_ORDER_SUCCESS = '@orders/send-add-customer-order-success';
export const SEND_ADD_CUSTOMER_ORDER_ERROR = '@orders/send-add-customer-order-error';

export const SEND_REMOVE_CUSTOMER_ORDER = '@orders/send-remove-customer-order';
export const SEND_REMOVE_CUSTOMER_ORDER_SUCCESS = '@orders/send-remove-customer-order-success';
export const SEND_REMOVE_CUSTOMER_ORDER_ERROR = '@orders/send-remove-customer-order-error';

export const SET_CUSTOMER_ORDERS = '@orders/set-customer-orders';
export const GET_CUSTOMER_ORDERS = '@orders/get-customer-orders';
export const GET_CUSTOMER_ORDERS_SUCCESS = '@orders/get-customer-orders-success';
export const GET_CUSTOMER_ORDERS_ERROR = '@orders/get-customer-orders-error';

export const SET_CUSTOMER_ORDERS_QUERY = '@orders/set-customer-orders-query';

export const SET_SELECTED_CUSTOMER_ORDERS = '@orders/set-selected-customer-orders';

export const SET_PARTY_GUESTS = '@orders/set-party-guests';
export const GET_PARTY_GUESTS = '@orders/get-party-guests';
export const GET_PARTY_GUESTS_SUCCESS = '@orders/get-party-guests-success';
export const GET_PARTY_GUESTS_ERROR = '@orders/get-party-guests-error';

export const SET_PARTY_GUESTS_QUERY = '@orders/set-party-guests-query';

export const SET_SELECTED_PARTY_GUESTS = '@orders/set-selected-party-guests';

export const SET_VISIBLE_MODAL_FORM_NUMBER = '@orders/set-visible-modal-form-number';

export const getCustomer = (customerId) => async (dispatch) => {
  dispatch({
    type: GET_CUSTOMER,
  });
  try {
    const response = await axios.get(`/api/contacts/${customerId}`);
    dispatch({
      type: GET_CUSTOMER_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_CUSTOMER_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_CUSTOMER_ERROR,
    });
  }
};

export const deleteCustomer = (customerId) => async (dispatch) => {
  dispatch({
    type: DELETE_CUSTOMER,
  });
  try {
    const response = await axios.delete(`/api/contacts/${customerId}`);
    const payload = response?.data?.success;
    dispatch({
      type: DELETE_CUSTOMER_SUCCESS,
      payload,
    });
    if (!payload) {
      dispatch(getCustomer(customerId));
      dispatch(addNotification(DELETE_CUSTOMER_ERROR, `Can not delete contact ${customerId}`, NOTIFICATION_STATUS.ERROR));
      return;
    }
    dispatch(addNotification(DELETE_CUSTOMER_SUCCESS, 'Contact was deleted', NOTIFICATION_STATUS.SUCCESS));
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(DELETE_CUSTOMER_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch(getCustomer(customerId));
    dispatch({
      type: DELETE_CUSTOMER_ERROR,
    });
  }
};

export const clearCustomerWasDeleted = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CUSTOMER_WAS_DELETED,
  });
};

export const updateCustomer = (contactId, contactChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_CUSTOMER,
    });
    const { updateBirthdayCampaignInPB } = getState()?.customerDetails || {};
    const response = await axios.put('/api/contacts', {
      contactChanges,
      updateBirthdayCampaignInPB,
    });
    const {
      success,
      partyGuest,
      createOrUpdateContactInPBError,
    } = response?.data || {};

    if (!success) {
      dispatch(addNotification(UPDATE_CUSTOMER_ERROR, 'Can not update contact', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: UPDATE_CUSTOMER_ERROR,
      });
      return;
    }

    if (createOrUpdateContactInPBError) {
      dispatch(addNotification(UPDATE_CUSTOMER_SUCCESS, createOrUpdateContactInPBError, NOTIFICATION_STATUS.ERROR));
    }

    await dispatch(getCustomer(contactId));
    await dispatch(setBackToPartyGuestList({
      partyGuest,
    }));
    if (!createOrUpdateContactInPBError) {
      dispatch(addNotification(UPDATE_CUSTOMER_SUCCESS, 'The contact has been successfully updated', NOTIFICATION_STATUS.SUCCESS));
    }
    dispatch({
      type: UPDATE_CUSTOMER_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(UPDATE_CUSTOMER_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: UPDATE_CUSTOMER_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const createCustomer = (contactChanges, doWhenTry = false, doWhenCatch = false) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREATE_CUSTOMER,
    });
    const { updateBirthdayCampaignInPB } = getState()?.customerDetails || {};
    const response = await axios.post('/api/contacts/create', {
      contactChanges,
      updateBirthdayCampaignInPB,
    });
    const {
      id,
      firstName,
      lastName,
      phone1,
      createOrUpdateContactInPBError,
    } = response?.data || {};

    if (!id) {
      dispatch(addNotification(CREATE_CUSTOMER_ERROR, 'Can not create contact', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: CREATE_CUSTOMER_ERROR,
      });
      return;
    }

    if (createOrUpdateContactInPBError) {
      dispatch(addNotification(CREATE_CUSTOMER_ERROR, createOrUpdateContactInPBError, NOTIFICATION_STATUS.ERROR));
    } else {
      dispatch(addNotification(CREATE_CUSTOMER_SUCCESS, 'Your contact has been successfully created', NOTIFICATION_STATUS.SUCCESS));
    }

    dispatch({
      type: CREATE_CUSTOMER_SUCCESS,
    });

    if (doWhenTry) {
      const options = {
        firstName,
        lastName,
        phone1,
      };
      doWhenTry(id, options);
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(CREATE_CUSTOMER_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: CREATE_CUSTOMER_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setUpdateBirthdayCampaignInPB = (payload) => async (dispatch) => {
  dispatch({
    type: SET_UPDATE_BIRTHDAY_CAMPAIGN_IN_PB,
    payload,
  });
};

export const setCustomerDetailsTab = (tabName) => async (dispatch) => {
  dispatch({
    type: SET_CUSTOMER_DETAILS_TAB,
    payload: tabName
  });
};

export const setGoToOrderDetails = (contactId, wasReturned = false, query = false) => async (dispatch) => {
  dispatch({
    type: SET_GO_TO_ORDER_DETAILS,
    payload: {
      contactId,
      wasReturned,
      ...query ? { query } : {},
    },
  });
};

export const clearGoToOrderDetails = (all = false) => async (dispatch) => {
  dispatch({
    type: CLEAR_GO_TO_ORDER_DETAILS,
    payload: all,
  });
};

export const setGoToPartyDetails = (contactId, wasReturned = false, query = false) => async (dispatch) => {
  dispatch({
    type: SET_GO_TO_PARTY_DETAILS,
    payload: {
      contactId,
      wasReturned,
      ...query ? { query } : {},
    },
  });
};

export const clearGoToPartyDetails = (all = false) => async (dispatch) => {
  dispatch({
    type: CLEAR_GO_TO_PARTY_DETAILS,
    payload: all,
  });
};

export const setFormCreateUpdateContact = (payload) => ({
  type: SET_FORM_CREATE_UPDATE_CONTACT,
  payload
});

export const getCustomerOrders = (phoneNumbers) => async (dispatch, getState) => {
  dispatch({
    type: GET_CUSTOMER_ORDERS,
  });

  const params = {
    sort: getState().customerDetails.customerOrdersQuery.sort,
    phoneNumbers,
  };

  try {
    const response = await axios.post('/api/orders/customerorders', { params });
    dispatch({
      type: GET_CUSTOMER_ORDERS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_CUSTOMER_ORDERS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_CUSTOMER_ORDERS_ERROR,
    });
  }
};

export const setCustomerOrders = (customerOrders) => ({
  type: SET_CUSTOMER_ORDERS,
  payload: customerOrders,
});

export const setCustomerOrdersQuery = (query) => ({
  type: SET_CUSTOMER_ORDERS_QUERY,
  payload: query
});

export const setSelectedCustomerOrders = (selectedCustomerOrders) => ({
  type: SET_SELECTED_CUSTOMER_ORDERS,
  payload: selectedCustomerOrders
});

export const getOrders = () => async (dispatch) => {
  dispatch({
    type: GET_ORDERS_LIST,
  });
  try {
    const response = await axios.get('/api/orders/orderslist');
    dispatch({
      type: GET_ORDERS_LIST_SUCCESS,
      payload: response?.data?.orders || []
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_ORDERS_LIST_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_ORDERS_LIST_ERROR,
    });
  }
};

export const getParties = () => async (dispatch) => {
  dispatch({
    type: GET_PARTIES_LIST,
  });
  try {
    const response = await axios.get('/api/parties/list');
    dispatch({
      type: GET_PARTIES_LIST_SUCCESS,
      // payload: response?.data?.parties || {}
      payload: response?.data?.parties || []
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTIES_LIST_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTIES_LIST_ERROR,
    });
  }
};
// export const setParties = (parties) => ({
//   type: SET_PARTIES,
//   payload: parties,
// });

export const getPartyGuests = () => async (dispatch, getState) => {
  dispatch({
    type: GET_PARTY_GUESTS,
  });

  const params = {
    sort: getState().customerDetails.partyGuestsQuery.sort,
    screen: 'contacts',
  };

  try {
    const response = await axios.post(`/api/parties/${getState().customerDetails.customer.id}/guests`, { params });
    dispatch({
      type: GET_PARTY_GUESTS_SUCCESS,
      payload: response?.data || {}
    });
  } catch (e) {
    const {
      error,
      message,
    } = e?.response?.data || {};
    dispatch(addNotification(GET_PARTY_GUESTS_ERROR, helpers.transform.toString(message || error || 'Server error'), NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: GET_PARTY_GUESTS_ERROR,
    });
  }
};

export const setPartyGuests = (partyGuests) => ({
  type: SET_PARTY_GUESTS,
  payload: partyGuests,
});

export const setPartyGuestsQuery = (query) => ({
  type: SET_PARTY_GUESTS_QUERY,
  payload: query
});

export const setSelectedPartyGuests = (selectedPartyGuests) => ({
  type: SET_SELECTED_PARTY_GUESTS,
  payload: selectedPartyGuests
});

// export const setSelectedContacts = (selectedContacts) => ({
//   type: SET_SELECTED_CONTACTS,
//   payload: selectedContacts
// });

export const setVisibleModalFormNumber = (modalNumber) => ({
  type: SET_VISIBLE_MODAL_FORM_NUMBER,
  payload: modalNumber
});

export const setValuesFormAddToParty = (values) => ({
  type: SET_VALUES_FORM_ADD_TO_PARTY,
  payload: values
});

export const sendAddPartyGuest = (addPartyGuests, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_ADD_PARTY_GUEST,
    });
    const response = await axios.put('/api/parties/addpartyguests', { addPartyGuests });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_ADD_PARTY_GUEST_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_ADD_PARTY_GUEST_ERROR,
      });
      return;
    }

    dispatch(addNotification(SEND_ADD_PARTY_GUEST_SUCCESS, 'The party has been added.', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_ADD_PARTY_GUEST_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_ADD_PARTY_GUEST_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_ADD_PARTY_GUEST_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const sendRemovePartyGuest = (selectedPartyGuests, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_REMOVE_PARTY_GUEST,
    });
    const response = await axios.put('/api/parties/removepartyguests', { selectedPartyGuests });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_REMOVE_PARTY_GUEST_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_REMOVE_PARTY_GUEST_ERROR,
      });
      return;
    }

    // const removeSuccessMessage = selectedParty.length > 1 ? 'The guests have been removed' : 'The guest has been removed';

    // dispatch(addNotification(SEND_REMOVE_PARTY_GUESTS_SUCCESS, removeSuccessMessage, NOTIFICATION_STATUS.SUCCESS));
    dispatch(addNotification(SEND_REMOVE_PARTY_GUEST_SUCCESS, 'The party has been removed', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_REMOVE_PARTY_GUEST_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_REMOVE_PARTY_GUEST_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_REMOVE_PARTY_GUEST_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const setValuesFormAddCustomerOrder = (values) => ({
  type: SET_VALUES_FORM_ADD_CUSTOMER_ORDER,
  payload: values
});

export const sendAddCustomerOrder = (addCustomerOrders, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_ADD_CUSTOMER_ORDER,
    });
    const response = await axios.put('/api/parties/addpartyguests', { addCustomerOrders });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_ADD_CUSTOMER_ORDER_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_ADD_CUSTOMER_ORDER_ERROR,
      });
      return;
    }

    dispatch(addNotification(SEND_ADD_CUSTOMER_ORDER_SUCCESS, 'The party has been added.', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_ADD_CUSTOMER_ORDER_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_ADD_CUSTOMER_ORDER_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_ADD_CUSTOMER_ORDER_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};

export const sendRemoveCustomerOrder = (selectedCustomerOrders, doWhenTry = false, doWhenCatch = false) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_REMOVE_PARTY_GUEST,
    });
    const response = await axios.put('/api/parties/removepartyguests', { selectedCustomerOrders });
    const { success } = response?.data || {};

    if (!success) {
      dispatch(addNotification(SEND_REMOVE_PARTY_GUEST_ERROR, 'Try again later', NOTIFICATION_STATUS.ERROR));
      dispatch({
        type: SEND_REMOVE_PARTY_GUEST_ERROR,
      });
      return;
    }

    dispatch(addNotification(SEND_REMOVE_PARTY_GUEST_SUCCESS, 'The party has been removed', NOTIFICATION_STATUS.SUCCESS));
    dispatch({
      type: SEND_REMOVE_PARTY_GUEST_SUCCESS,
    });

    if (doWhenTry) {
      doWhenTry();
    }
  } catch (e) {
    const {
      message,
      error,
    } = e?.response?.data || {};
    const { message: errorMessage } = error || {};
    dispatch(addNotification(SEND_REMOVE_PARTY_GUEST_ERROR, message || errorMessage || 'Try again later', NOTIFICATION_STATUS.ERROR));
    dispatch({
      type: SEND_REMOVE_PARTY_GUEST_ERROR,
    });

    if (doWhenCatch) {
      doWhenCatch(e);
    }
  }
};
